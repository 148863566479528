<template>
    <div class="page view">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-md-12">
                    <h3 class="mt-5 mb-4 font-weight-bold">
                        {{ $t('modules.' + module.name + "_single") }} <i v-if="!payload" class="pull-right fas fa-spinner fa-spin"></i><br><br>
                    </h3>
                </div>
            </div>
            <!-- Content -->
            <div class="row">
                <aside class="col-md-4 blog-sidebar" v-if="payload">
                    <div class="card mb-4 card-shadow">

                       
                        <!-- Show Image if no Map -->
                        <div class="image" v-if="payload.images && payload.images.length > 0">
                            <div class="card-big-img" :style="'background-image: url(' + site_root + payload.images[0].original + ')'"></div>
                        </div>

                        <div class="image" v-if="payload.image">
                            <div class="card-big-img" :style="'background-image: url(' + site_root + payload.image.original + ')'"></div>
                        </div>

                        <div class="card-body card-body-big">

                            <!-- Has Category ? -->
                            <div class="contactBox" v-if="payload.type">
                                <div class="contactBox-icon">
                                    <i class="fas fa-tag boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.category') }}</p>
                                    <p>{{ (payload.type.section) ? payload.type.section.name : payload.type.name }}</p>
                                </div>
                            </div>


                            <div class="contactBox" v-if="payload.party_name">
                                <div class="contactBox-icon">
                                    <i class="fas fa-tag boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.party_name') }}</p>
                                    <p>{{ payload.party_name }}</p>
                                </div>
                            </div>

                            <div class="contactBox" v-if="payload.partner">
                                <div class="contactBox-icon">
                                    <i class="fas fa-tag boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.partner') }}</p>
                                    <p>{{ payload.partner }}</p>
                                </div>
                            </div>


                            <div class="contactBox" v-if="payload.function">
                                <div class="contactBox-icon">
                                    <i class="fas fa-tag boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.function') }}</p>
                                    <p>{{ payload.function }}</p>
                                </div>
                            </div>

                            <div class="contactBox" v-if="payload.address || payload.location">
                                <div class="contactBox-icon">
                                    <i class="fas fa-map-marker-alt boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.address') }}</p>
                                    <p>{{ payload.address }} {{ payload.location }}</p>
                                </div>
                            </div>

                          

                            <div class="contactBox" v-if="payload.phone">
                                <div class="contactBox-icon">
                                    <i class="fas fa-phone-volume boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.phone') }}</p>
                                    <p>{{ payload.phone }}</p>
                                </div>
                            </div>

                            <div class="contactBox" v-if="payload.email">
                                <div class="contactBox-icon">
                                    <i class="fas fa-envelope boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.email') }}</p>
                                    <p>{{ payload.email }}</p>
                                </div>
                            </div>

                            <div class="contactBox" v-if="payload.link">
                                <div class="contactBox-icon">
                                    <i class="fas fa-globe boxIcon"></i>
                                </div>
                                <div class="contactBox-body">
                                    <p class="font-weight-bold text-uppercase">{{ $t('labels.link') }}</p>
                                    <p>{{ payload.link }}</p>
                                </div>
                            </div>

                            <br><br>
                        </div>
                    </div>
                </aside>
                <div class="col-md-8 blog-main" v-if="payload">

                    <!-- Header -->
                    <h3 class="font-weight-bold">
                        {{ payload.title }} {{ payload.first_name }} {{ payload.last_name }}
                    </h3>
                    <h5 v-if="payload.function">{{ payload.function }}</h5>
                    <h5 v-if="payload.party_name">{{ payload.party_name }}</h5>
                    <br>

                     <!-- Map -->
                    <div v-if="payload.lng && payload.lat">
                        <h5 class="subtitle">{{ $t('labels.location') }}</h5>
                        <show-map :multiple="false" :payload="payload" />
                        <br>
                    </div>

                    <!-- Map -->
                    <div v-if="payload.transport_stations">
                        <h5 class="subtitle">{{ $t('modules.transport_stations') }}</h5>
                        <show-map :multiple="true" :payload="payload" :field="(item) => item.transport_stations" />
                        <br>
                        <div class="stations">
                            <div class="station" v-for="station in payload.transport_stations">
                                <router-link :to="'/transport-stations/' + station.id">
                                    {{ station.title }}
                                </router-link>
                            </div>
                        </div>
                        <br>
                    </div>

                    <!-- Map -->
                    <div v-if="payload.interest_points">
                        <h5 class="subtitle">{{ $t('modules.interest_points') }}</h5>
                        <show-map :multiple="true" :payload="payload" :field="(item) => item.interest_points" />
                        <br>
                        <div class="stations">
                            <div class="station" v-for="station in payload.interest_points">
                                <router-link :to="'/interest-points/' + station.id">
                                    {{ station.title }}
                                </router-link>
                            </div>
                        </div>
                        <br>
                    </div>

                    <!-- Schedule -->
                    <div class="schedule" v-if="payload.schedule && payload.schedule.length > 0">
                        <h5 class="subtitle">{{ $t('labels.schedule') }}</h5>
                        <show-schedule :payload="payload" /> 
                    </div>

                    <!-- Content -->
                    <div v-if="payload.description">
                        <h5 class="subtitle">{{ $t('labels.description') }}</h5>
                        <div class="blog-post" v-html="payload.description">
                        </div><br>
                    </div>

                  

                    <!-- Uploads -->
                    <div class="files" v-if="payload.uploads">
                        <h5 class="subtitle">{{ $t('labels.uploads') }}</h5>
                    	<div class="file" v-for="file in payload.uploads">
                    		<a :href="site_root + file.original">  
                    			<i class="fa fa-file"></i> {{ file.name}}
                    			<div class="pull-right">Download</div>
                    		</a>
                        <br>
                    	</div> 
                    </div>

                    <!-- Images -->
                    <div class="images" v-if="payload.images">
                        <h5 class="subtitle">{{ $t('labels.images') }}</h5>
                        <div class="row">
                        <div class="image col-6" v-for="image in payload.images">
                            <img :src="site_root + image.original" alt="" class="img-fluid">
                        </div>
                        </div>
                        <br>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .subtitle  { 
        font-size: 16px;
        color: #004680;
        font-weight: 700;
    }
</style>
<script>
import ShowMap from '../components/ShowMap.vue';
import ShowSchedule from '../components/ShowSchedule.vue';
export default {
    props: ['module'],
    components: {
        'show-map': ShowMap,
        'show-schedule': ShowSchedule,
    },
    data() {
        return {
            payload: null,
            site_root: 'https://sm.weareomni.eu/'
        }
    },
    mounted() {

        var id = this.$route.params.id;
        this.$http.get(this.module.resource + '/' + id)
            .then(res => {
                this.payload = res.data;
            })
            .catch(err => {

            });

    }
}
</script>