<template>
    <div id="app">
        
        <navigation />

        <language-select />

        <router-view />
        
        <footer class="text-muted">
            <div class="container customFooter">
                <p class="text-center">Copyright © Primăria Satu Mare. Toate drepturile rezervate.</p>
            </div>
        </footer>
    </div>
</template>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
<script>
import Navigation from './components/Navigation.vue';
import LanguageSelect from './components/LanguageSelect.vue';
export default {
    components: {
        'navigation': Navigation,
        'language-select': LanguageSelect
    }
}
</script>