<template>
    <div class="home">
        <div class="container">
            <div class="row box ">
                <div class="col-12 box-head">
                    {{ $t('sections.news') }}
                </div>
                <div class="col--12 box-body">
                    <!-- {{ payload.news }} -->
                </div>
                <!-- Cariousel Area -->
                <div class="col-12 box-body" v-if="payload">
                    <carousel :nav="false" :items="1" id="carouselNews" class="row carousel slide customShadow">
                        <div class="col-12 no-padding" v-for="(item, index) in payload.news" v-bind:key="item.id">
                            <div v-if="item.images[0]" class="carousel-image" :style="'background-image: url(' + site_root + item.images[0].original + ')'">
                                <div class="carousel-body">
                                    <span>{{ item.created_at | moment("DD.MM.YYYY HH:mm") }}</span>
                                    <h2>{{ item.title }}</h2>
                                    <p class="desc">{{ item.description.substring(0, 800) }}
                                    </p>
                                    <router-link :to="'/news/' + item.id">
                                        <button class="btn btn-primary customBtn customBtn-white">Vezi mai multe</button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 no-padding" v-for="(item, index) in payload.events" v-bind:key="item.id">
                            <div v-if="item.images[0]" class="carousel-image" :style="'background-image: url(' + site_root + item.images[0].original + ')'">
                                <div class="carousel-body">
                                    <span>{{ item.created_at | moment("DD.MM.YYYY HH:mm") }}</span>
                                    <h2>{{ item.title }}</h2>
                                    <p class="desc">{{ item.description.substring(0, 800) }}
                                    </p>
                                    <router-link :to="'/events/' + item.id">
                                        <button class="btn btn-primary customBtn customBtn-white">Vezi mai multe</button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </carousel>
                </div>
                <!-- Noutati -->
                <div class="col-sm-12" v-if="payload">
                    <div class="row box no-pb">
                        <div class="col-12 box-head">
                            {{ $t('labels.updates') }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 box-body">
                            <div class="row">
                                <div class="col-md-4 ">
                                    <div class="smallBox shadow">
                                        <div class="smallBox-head">{{ $t('modules.projects') }}</div>
                                        <div class="smallBox-body">
                                            <ul>
                                                <li v-for="item in payload.projects">
                                                    <router-link :to="'/projects/' + item.id">
                                                        {{ item.title }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="smallBox-footer">
                                            <router-link to="/projects">
                                                <button class="btn btn-primary customBtn customBtn-blue">Vezi mai multe</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <div class="smallBox shadow">
                                        <div class="smallBox-head">{{ $t('modules.activities') }}</div>
                                        <div class="smallBox-body">
                                            <ul>
                                                <li v-for="item in payload.activities">
                                                    <router-link :to="'/activities/' + item.id">
                                                        {{ item.title }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="smallBox-footer">
                                            <router-link to="/activities">
                                                <button class="btn btn-primary customBtn customBtn-blue">Vezi mai multe</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <div class="smallBox shadow">
                                        <div class="smallBox-head">{{ $t('modules.partnerships') }}</div>
                                        <div class="smallBox-body">
                                            <ul>
                                                <li v-for="item in payload.partnerships">
                                                    <router-link :to="'/partnerships/' + item.id">
                                                        {{ item.title }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="smallBox-footer">
                                            <router-link to="/partnerships">
                                                <button class="btn btn-primary customBtn customBtn-blue">Vezi mai multe</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Contact -->
                <div class="col-sm-12">
                    <div class="row box">
                        <div class="col-12 box-head">
                            {{ $t('labels.contact_form') }}
                        </div>
                        <div class="col-12 box-body">
                            <div class="row no-margin shadow">
                                <div class="col-md-7">
                                    <div class="row contactsContainer">
                                        <div class="col-md-12">
                                            <div class="contactBox">
                                                <div class="contactBox-icon">
                                                    <i class="fas fa-map-marker-alt boxIcon"></i>
                                                </div>
                                                <div class="contactBox-body">
                                                    <p class="font-weight-bold text-uppercase">Adresa</p>
                                                    <p>P-ta 25 Octombrie, Nr. 1 Corp M, 440026 Satu Mare</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="contactBox">
                                                <div class="contactBox-icon">
                                                    <i class="fas fa-phone-volume boxIcon"></i>
                                                </div>
                                                <div class="contactBox-body">
                                                    <p class="font-weight-bold text-uppercase">Telefon</p>
                                                    <p>0261-807.566</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="contactBox">
                                                <div class="contactBox-icon">
                                                    <i class="fas fa-fax boxIcon"></i>
                                                </div>
                                                <div class="contactBox-body">
                                                    <p class="font-weight-bold text-uppercase">FAX</p>
                                                    <p>0261-710.760</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="contactBox">
                                                <div class="contactBox-icon">
                                                    <i class="far fa-envelope boxIcon"></i>
                                                </div>
                                                <div class="contactBox-body">
                                                    <p class="font-weight-bold text-uppercase">EMAIL</p>
                                                    <p>primaria@primariasm.ro</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="contactBox">
                                                <div class="contactBox-icon">
                                                    <i class="fas fa-globe boxIcon"></i>
                                                </div>
                                                <div class="contactBox-body">
                                                    <p class="font-weight-bold text-uppercase">WEBSITE</p>
                                                    <p>http://www.satu-mare.ro</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row contactsMap">
                                        <div class="col-md-12" id="map">
                            			</div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="row">
                                        <div class="col-md-12 formBox">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center serviceBox serviceBox-white">
                                                        <i class="fab fa-wpforms"></i>
                                                        <div>Trimite un mesaj administratorului paginii primariei municipiului</div>
                                                    </div>
                                                    <form method="POST" action="/sendMessage">
                                                        <input type="hidden" name="_token" value="HGK7HCj5vpk6miseLjFwS8UY0EJXcbJoVWDIwbep">
                                                        <div class="form-group">
                                                            <label for="name" class="text-uppercase">Nume*</label>
                                                            <input type="text" class="materializeInput" name="name" id="name" placeholder="Nume" required="">
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="email" style="text-uppercase">Email*</label>
                                                            <input type="email" class="materializeInput" name="email" id="email" placeholder="Email" required="">
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="message" class="text-uppercase">Mesaj*</label>
                                                            <input type="text" class="materializeInput" name="message" id="message" placeholder="Mesaj" required="">
                                                        </div>
                                                        <div class="g-recaptcha" data-sitekey="6Le--GgUAAAAAETiqt25MrmjAsl7fhsuooiJu0cy">
                                                            <div style="width: 304px; height: 78px;">
                                                                <div><iframe src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Le--GgUAAAAAETiqt25MrmjAsl7fhsuooiJu0cy&amp;co=aHR0cDovL3NpdGUtc20uY2xvdWQucm86ODA.&amp;hl=en&amp;v=qc5B-qjP0QEimFYUxcpWJy5B&amp;size=normal&amp;cb=zc3gwawyd9n8" width="304" height="78" role="presentation" name="a-j2pgpyiur3ef" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea>
                                                            </div><iframe style="display: none;"></iframe>
                                                        </div>
                                                        <small class="form-text text-muted mb-2">* Toate campurile sunt obligatorii
                                                        </small>
                                                        <div>
                                                            <button type="submit" class="btn btn-primary customBtn customBtn-white w-100">
                                                                Trimite mesaj
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 newsletterBox">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center serviceBox serviceBox-white">
                                                        <i class="fab fa-wpforms"></i>
                                                        <div>Inscrieti-va la newsletterul primariei pentru a fi la curent cu ultimele informatii</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <form action="/registerNewsletter" method="POST" class="newsletterForm">
                                                        <input type="hidden" name="_token" value="HGK7HCj5vpk6miseLjFwS8UY0EJXcbJoVWDIwbep">
                                                        <div class="form-group newsletterForm-body">
                                                            <label for="email">EMAIL*</label>
                                                            <input type="email" class="materializeInput materializeInput-white" id="email" placeholder="Email" name="email" required="">
                                                        </div>
                                                        <div class="newsletterForm-footer">
                                                            <button type="submit" class="btn btn-primary customBtn customBtn-white">
                                                                OK
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.smallBox {
    border-radius: 6px;
    height: 320px;
}

.carousel {
    margin-left: 0px;
    margin-right: 0px;
}

.container {
    text-align: left;
}

.desc {
    font-weight: 700;
}

.smallBox-body li {
    line-height: 16px;
}

.contactsMap {
    height: 542px;
}

</style>
<script>
import carousel from 'vue-owl-carousel2'
export default {
    name: 'Home',
    components: { carousel },
    data() {
        return {
            site_root: 'https://sm.weareomni.eu/',
            payload: null,
        }
    },
    mounted() {
        console.log('up');

        this.$http.get('/dashboard?has_news=true')
            .then(res => {
                this.payload = res.data;
                this.initMap();


            })
            .catch(err => {

            });
    },
    methods: {

    	initMap() {
            let satuMare = {lat: 47.790, lng: 22.873};
            let map = new google.maps.Map(
                document.getElementById('map'), {zoom: 17, center: satuMare});
            let marker = new google.maps.Marker({position: satuMare, map: map});
        }

    }
}
</script>