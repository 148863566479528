<template>
	<div class="index">
		<router-view ref="view" :module="module"></router-view>
	</div>
</template>
<script>
export default { 
	data() { 
		return { 
			module: {
				name: 'transport_ticket_shops', 
	            resource: 'transport-ticket-shops', 

	            fields: {
	            	index: [
	            		 {
                            field: item => item.title,
                            fieldname: 'title',
                            label: 'labels.title',
                            filter: 'text',
                        }, 
                         { 
                            field: item => item.location,
                            fieldname: 'address',
                            label: 'labels.address',
                            filter: 'text',
                        },
                       
                        
	            	],

	            	view: [ 
	            		
	            	]
	            }
            }
		}
	}
}
</script>