<template>

    <div class="map">
        <gmap-map 
         v-if="enabled"
    	:center="center" 
    	:zoom="13" 
    	style="width:100%;  height: 280px;" 
    	:options="map_options">

    	<gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"

         ></gmap-marker>

      </gmap-map>
    </div>
      
</template>
<script>
export default {
    props: ['payload', 'multiple', 'field'],
    data() {
    	return {
    		map_options: {
	    		zoomControl: true,
	            mapTypeControl: false,
	            scaleControl: false,
	            streetViewControl: false,
	            rotateControl: false,
	            fullscreenControl: false,
	            disableDefaultUi: true,
                controlSize: 28,
                clickableIcons: false,
                disableDoubleClickZoom: true,
    		},
    		center: { lat: 44.439663, lng: 26.096306 },
      		markers: [],
      		places: [],
      		currentPlace: null,
            enabled: false,
            zoom: 16, 

    	}
    },
    mounted() {


        // Single pinpoint from object 
        if(!this.multiple) {

        	if(this.payload.lat && this.payload.lng) {
        		//console.log(this.payload.lat);
        		//console.log(this.payload.lng);

        		const marker = {
    	          lat: parseFloat(this.payload.lat),
    	          lng: parseFloat(this.payload.lng),
    	        };

            	this.markers.push({ position: marker });
            	this.center = marker;

                this.enabled = true;

        	} 

        }
        // Multiple pinpoints
        else {

            var points = this.field(this.payload);

            this.zoom = 14;

            for (var i = 0; i < points.length; i++) {

                var point = points[i];

                const marker = {
                    label: point.title,
                    lat: parseFloat(point.lat),
                    lng: parseFloat(point.lng),
                }

                this.markers.push({ position: marker });
                this.center = marker;
                this.enabled = true;



            }



        }
    	
    },
}

</script>
