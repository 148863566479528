import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';

Vue.use(VueRouter);

import ListPage from './views/List.vue'; 
import ViewPage from './views/View.vue';


// Interactioneaza
import RequiredDocuments from './modules/required-documents';
import Forms from './modules/forms';
import Proposals from './modules/proposals';
import Problems from './modules/problems';
 
// Stiri Evenimente
import News from './modules/news';
import Events from './modules/events';

// Turism
import Tours from './modules/tours';
import InterestPoints from './modules/interest-points';

// Transport
import TransportTicketShops from './modules/transport-ticket-shops';
import TransportStations from './modules/transport-stations';
import TransportLines from './modules/transport-lines';

// Primarie
import Abouts from './modules/abouts';
import Managers from './modules/managers';
import Councillors from './modules/councillors';
import Projects from './modules/projects';
import Activities from './modules/activities';
import Partnerships from './modules/partnerships'; 


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // Interactioneaza
  {
    path: '/required-documents',
    component: RequiredDocuments,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/forms',
    component: Forms,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/proposals',
    component: Proposals,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/problems',
    component: Problems,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  // Stiri / Evenimente

  {
    path: '/news',
    component: News,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/events',
    component: Events,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  // Turism
  {
    path: '/tours',
    component: Tours,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/interest-points',
    component: InterestPoints,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  // Transport
  {
    path: '/transport-ticket-shops',
    component: TransportTicketShops, 
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/transport-lines',
    component: TransportLines, 
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/transport-stations',
    component: TransportStations, 
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },


  // Primarie

  {
    path: '/abouts',
    component: Abouts,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/managers',
    component: Managers,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },
  {
    path: '/councillors',
    component: Councillors,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  {
    path: '/projects',
    component: Projects,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  {
    path: '/activities',
    component: Activities,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  },

  {
    path: '/partnerships',
    component: Partnerships,
    children: [
        { path: '/', component: ListPage},
        { path: ':id', component: ViewPage}
    ]
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: '/site/',
  routes
})

export default router
