import Vue from 'vue'
import Axios from 'axios'
import VueI18n from 'vue-i18n'

import * as VueGoogleMaps from "vue2-google-maps";

import router from './routes.js'
import store from './store' 
import messages from './translations';



import App from './App.vue'



Vue.config.productionTip = false

/**
 * Google Maps
 */

// Google Maps Settings
Vue.use(VueGoogleMaps, {
  load: {
    region: 'RO',
    language: 'ro',
    key: "AIzaSyCT_OPbslOxmsJieNn58HsbZFmQBQ6os0U", 
    libraries: "places" // necessary for places input
  }
});

/**
 * i18n Translations
 */

Vue.use(VueI18n);

let lang = localStorage.getItem('lang');
if(!lang) 
	lang = 'ro'; 

const i18n = new VueI18n({
	locale: lang,
	fallbackLocale: 'ro',
	messages,
});


/** 
 * Axios
 */ 
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; 
Vue.prototype.$http.defaults.headers["content-type"] = "application/json";
Vue.prototype.$http.defaults.crossDomain = true; 
Vue.prototype.$http.defaults.baseURL = 'https://sm.weareomni.eu/api/client/';  

Vue.prototype.getImage = function(fileName) {
  return 'https://remote2all.cloud.ro/business/api/v1/media/' + fileName;
}

// Load token from local storage
const token = localStorage.getItem('token');
if(token) {
  const auth = JSON.parse(token);
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + auth.access_token;
} 
 
require('moment/locale/ro')
Vue.use(require('vue-moment'), {
  moment
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
