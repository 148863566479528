<template>
	<div class="index">
		<router-view ref="view" :module="module"></router-view>
	</div>
</template>
<script>
export default { 
	data() { 
		return {
			module: {
                
				name: 'councillors', 
	            resource: 'councillors', 

	            fields: {
	            	index: [

	            		{
                            field: item => item.first_name + ' ' + item.last_name,
                            fieldname: 'name',
                            label: 'labels.name',
                            filter: 'text',
                        },
                        {
                            field: item => (item.party_name) ? item.party_name : this.$t('without'),
                            fieldname: 'party_name',
                            label: 'labels.party_name',
                            filter: 'text',
                        },
	            	],

	            	view: [
	            		
	            	]
	            }
            }
		}
	}
}
</script>