const ro = {
    without: 'Fără',
    empty: 'Fără rezultate',
    primarie: 'Primărie',
    reply_email: 'Email',
    reply_phone: 'Apel Telefonic',   
    1: 'Da',
    0: 'Nu',
    new: 'Nou',
    in: 'Intrare',
    out: 'Ieșire',
    preluat: 'Preluat', 
    respins: 'Respins',
    modificat: 'Modificat',
    directionat: 'Direcționat',
    finalizat: 'Finzalizat',
    anulat: 'Anulat',
    feedback: 'Feedback',
    approved: 'Aprobat',
    done: 'Sfarsit',
    redirected: 'Redirecționat',
    resolved: 'Rezolvat',
    unresolved: 'In lucru',
    rejected: 'Anulat',
    annuled: 'Anulat',
 	backend: 'Back End',
 	view: 'Vizualizare',
 	edit: 'Editează', 
    add: 'Adaugă', 
    no_role: 'Fără rol', 
    unconfirmed: 'Neconfirmat',
    confirmed: 'Confirmat',
    active: 'Activ',  
    suspended: 'Suspendat',
    blocked: 'Anulat',
    draft: 'Draft',
    questions: {
        solicitation: 'Ce solicitati de la primarie?',
        service: 'La ce serviciu din primarie v-ati adresat?',
        documents: 'Ce documente aveti?',
        user_answer: 'Ce raspuns ati primit?',
        user_number: 'Nr. de înregistrare a cererii depuse la primărie',
    },
 	interactions: {
 		confirmation_required: 'Confirmare necesară',
 		confirmation_delete: 'Sunteți sigur că doriți să ștergeți acest obiect ?',
        confirmation_delete_file: 'Sunteți sigur că doriți să ștergeți acest fișier ?',
        confirmation_remove_point: 'Sunteți sigur că doriți să ștergeți acest punct de interes de la această rută ?',
        confirmation_remove_station: 'Sunteți sigur că doriți să ștergeți acestă stație de la această rută ?',
        confirmation_remove_question: 'Sunteți sigur că doriți să ștergeți acestă întrebare ?',
 	},
 	buttons: {
        confirm: 'CONFIRMĂ',
 		add: 'ADAUGĂ',
 		edit: 'EDITEAZĂ',
 		delete: 'ȘTERGE', 
 		save: 'SALVEAZA',  
 		cancel: 'ÎNAPOI',         
        accept: 'ACCEPTĂ',
        revoke: 'ANULEAZĂ',
        resolve: 'REZOLVĂ',
        publish: 'PUBLICĂ',
        unpublish: 'SALVEAZĂ DRAFT',
        categories: 'CATEGORII',
        sections: 'SECȚIUNI', 
        send: 'TRIMITE',
 	},
    langs: {
        ro: 'Romană',
        en: 'Engleză',
        fr: 'Franceză',
        de: 'Germană',
    },
    days: {
        1: 'Luni',
        2: 'Marți', 
        3: 'Miercuri',
        4: 'Joi',  
        5: 'Vineri', 
        6: 'Sâmbătă', 
        7: 'Duminică',
    }, 
    resolutions: {
        preluat: 'Document Preluat', 
        respins: 'Document Respins',
        directionat: 'Document Direcționat',
        finalizat: 'Document Finzalizat',
        anulat: 'Document Anulat',
        modificat: 'Document Modificat',
    },
 	labels: { 
        contact_form: 'Date contact și formular',
        updates: 'Noutăți',
        appointment_person: 'Persoană',
        response: 'Răspuns',
        documents: 'Documente',
        solicitation: 'Solicitare',
        service: 'Serviciu',  
        starts_at: 'Ora Începere',
        ends_at:  'Ora Terminare',
        forwarded: 'Direcționat',
        timestamp: 'Data/Ora',
        user: 'Utilizator',
        resolution: 'Rezoluție',
        resolutions: 'Flux Document',
        add_resolution: 'Adaugă Rezoluție',
        person: 'Persoană Fizică',
        company: 'Persoană Juridică',
        contact: 'Solicitant',
        number: 'Număr',
        series: 'Serie',
        in: 'Intrare',
        out: 'Ieșire',
        cui: 'C.U.I.',
        reg_comert: 'Nr. Reg. Comerț',
        caen: 'Cod CAEN',
        activity: 'Activitate',
        cnp: 'C.N.P.',
        birthday: 'Dată Naștere', 
        profession: 'Profesie',
        workplace: 'Loc de muncă',
        direction: 'Direcție',
        in: 'Intrare',
        out: 'Ieșire',
        search: 'Caută',
        structure: 'Structură',
        members: 'Membrii',
        no_parent: 'Fără Părinte',
        parent: 'Părinte',
        status_modified_at: 'Status Modificat', 
        posts: 'Postări',
        redirected: 'Redirecționat', 
        all: 'Toate',
        send_to_institution: 'Redirecționează spre institutie', 
        posts: 'Postări',
        problem_types: 'Categorii Probleme',
        featured: 'Promovat',
        id: 'Nr.',
        city: 'Localitate',
 		details: 'Detalii',
 		title: 'Titlu',
 		type: 'Tip',
        text: 'Text',  
 		created_at: 'Data / Ora Postării',
 		filesize: 'Mărime fișier',
 		description: 'Descriere',
        uploads: 'Upload',
        showing_results: 'Rezultate', 
        of: 'din',
        first_name: 'Prenume',
        last_name: 'Nume',
        email: 'Email',
        phone: 'Telefon',
        address: 'Adresă', 
        reply_method: 'Răspuns prin',
        status: 'Status',
        rating: 'Apreciere', 
        ratings_number: 'Persoane care au apreciat',
        ratings_average: 'Media aprecierilor',
        more_info: 'Alte informații',
        images: 'Poze',
        location: 'Locație',
        category: 'Categorie',
        categories: 'Categorii',
        link: 'Link',
        stb_link: 'Link hartă traseu',
        start_date: 'Dată Început',
        end_date: 'Dată Sfârșit',
        organizer: 'Organizator',
        no_results: 'Fără rezultate',
        profile_image: 'Imagine',
        party_name: 'Partid Politic',
        role: 'Rol',
        name: 'Denumire',
        access: 'Acces',
        modules: 'Module',
        points_number: 'Nr. Puncte Turistice',
        tour_points: 'Puncte din Ruta Turistică',
        tourist_point: 'Punct Turistic',
        tourist_points: 'Punct Turistic',
        add_points_to_route: 'Adaugare Punct Turistic la Rută',
        add_station_to_line: 'Adaugare Stație la Rută',
        corelate_station: 'Corelare cu Stația', 
        schedule: 'Program',
        closed: 'Închis',
        access_blocked: 'Acces restrictionat',
        map: 'Hartă',
        stations_away: 'Stații rută tur',
        stations_back: 'Stații rută retur',
        arrive_time: 'Oră Sosire în Stație',
        questions: 'Întrebări',
        question: 'Întrebare',
        answers: 'Răspunsuri',
        answer: 'Răspuns',
        answer_count: 'Nr. Răspunsuri',
        multiple_choice: 'Răspunsuri Multiple',
        no: 'Nu', 
        yes: 'Da',
        participants: 'Participanți',
        votes: 'Voturi',
        vote: 'Vot',
        translations: 'Traduceri',
        studies: 'Studii',
        message: 'Mesaj',
        function: 'Funcție',
        appointed_date: 'Data Numirii',
        partner: 'Partener',
        predicted_outcomes: 'Rezultate Preconizate',
        investment_costs: 'Costuri Investiție',
        objective: 'Obiective',
        department: 'Departament',
        order: 'Ordine',
        password: 'Parolă',
        password_confirm: 'Confirmare Parolă',
        errors: 'Erori',
        duration: 'Durată',
        schedule_link: 'Link Program',
        send: 'Trimite',
        reject_reason: 'Motiv Anulare',
        comments: 'Comentarii',
        fax: 'Fax',
        message_to: 'Include mesaj către',
        can_retry_account_request: 'Permite retrimitere poză C.I.',
        ref_id: 'Nr. Ref.',
        section: 'Secțiune',
        sections: 'Secțiuni',

 	},
 	toasts: { 
        success: 'Succes',
 		delete_success: 'Obiect șters',
 		create_success: 'Obiect creat',
 		update_success: 'Obiect salvat', 
 		request_error: 'A fost o eroare in a procesarea cererii',
 		validation_error: 'Vă rugăm completați câmpurile indicate',
 	},
    auth: {
     	 email_label: 'Adresă email',
     	 password_label: 'Parola',
         connect: 'CONECTARE',
         logout: 'LOG OUT',
    },
    sections: {
        
        audiences: 'Progamări',
        registry: 'Registratură',
        contacts: 'Contacte',
        admin: 'Admin',
        interact: 'Interacționează',
        news: 'Știri / Evenimente',
        turism: 'Turism',
        transport: 'Transport și locații',
        general_points: 'Puncte de Interes General',
        what_we_do: 'SM / Cu ce ne ocupăm',
        about_us: 'Primărie',
    },
    modules: { 
        appointments: 'Audiențe',
        appointments_single: 'Audiență',
        registries: 'Registre',
        registries_single: 'Registru',
        registrations: 'Documente',
        registrations_single: 'Document',
        forms: 'Formulare',
        forms_single: 'Formular',
        people: 'Persoane Fizice', 
        people_single: 'Persoană Fizică', 
        companies: 'Persoane Juridice',
        companies_single: 'Persoană Juridică',
        organizations: 'Organigramă',
        organizations_single: 'Organizație',
        import: 'Import Puncte de Interes', 
        institutions: 'Instituții',
        institutions_single: 'Instituție',
        accounts: 'Utilizatori Externi',
        accounts_single: 'Utilizator',
        account_requests: 'Cereri Creare Cont', 
        account_requests_single: 'Cerere Creare Cont', 
    	required_documents: 'Acte Necesare',
    	required_documents_single: 'Act Necesar',
        information_requests: 'Solicitări Informații',  
        information_requests_single: 'Solicitare',  
        feedback: 'Feedback',
        feedback_single: 'Feedback',
        proposals: 'Inițiative / Propuneri',
        proposals_single: 'Propunere',
        problems: 'Probleme Raportate',
        problems_single: 'Problemă',
        news: 'Știri',
        news_single: 'Știre',
        events: 'Evenimente',
        events_single: 'Eveniment',
        interest_points: 'Puncte de Interes',
        interest_points_single: 'Punct de Interes',
        museums: 'Muzee',
        museums_single: 'Muzeu',
        monuments: 'Monumente',
        monuments_single: 'Monument',
        parks: 'Parcuri', 
        parks_single: 'Parc',
        agrement: 'Puncte de Agrement',
        agrement_single: 'Punct de Agrement',
        monastery: 'Biserici / Mănăstiri',
        monastery_single: 'Biserică / Mănăstire',
        hotels: 'Hoteluri',
        hotels_single: 'Hotel',
        restaurants: 'Restaurante',
        restaurants_single: 'Restaurant',
        coffeeshops: 'Cafenele', 
        coffeeshops_single: 'Cafenea',
        hospitals: 'Spitale',
        hospitals_single: 'Spital',
        pharmacies: 'Farmacii',
        pharmacies_single: 'Farmacie',
        clinics: 'Clinici',
        clinics_single: 'Clinică',
        commerce: 'Centre Comerciale',
        commerce_single: 'Centru Comercial',
        autoinfo: 'Service-uri Auto',
        autoinfo_single: 'Service Auto',
        police: 'Secții de Poliție',
        police_single: 'Secție',
        cityhalls: 'Instituții Publice',
        cityhalls_single: 'Instituție Publică',
        schools: 'Instituții de Învățământ',
        schools_single: 'Instituție de Învățământ',
        sport: 'Centre Sportive',
        sport_single: 'Centru Sportiv',
        roads: 'Drumuri Județene',
        roads_single: 'Drum Județean', 
        sewers: 'Apă canal',
        sewers_single: 'Apă canal',
        health: 'Rețeaua de sănătate',
        health_single: 'Punct de sănătate',
        waste: 'Deșeuri',
        waste_single: 'Deșeu',
        public_services: 'Servicii Publice',
        public_services_single: 'Serviciu Public',
        councillors: 'Consiliul Local',
        councillors_single: 'Consilier',
        services: 'Servicii',
        services_single: 'Serviciu',
        projects: 'Proiecte Strategice', 
        projects_single: 'Proiect',
        admins: 'Utilizatori Interni',
        admins_single: 'Utilizator',
        roles: 'Roluri',
        roles_single: 'Rol',
        abouts: 'Orașul Satu Mare',
        abouts_single: 'Conducerea Primăriei',  
        managers: 'Echipă Managerială',
        managers_single: 'Member',
        activities: 'Activități',
        activities_single: 'Activitate',
        partnerships: 'Parteneriate', 
        partnerships_single: 'Parteneriat',
        tours: 'Rute Turistice',
        tours_single: 'Rută Turistică', 
        transport_stations: 'Stații Transport Public',
        transport_stations_single: 'Stație',
        transport_ticket_shops: 'Case de bilete',
        transport_ticket_shops_single: 'Casă de bilete',
        transport_lines: 'Rute Transport Public',
        transport_lines_single: 'Rută Transport Public',
        surveys_edit: 'Creare Sondaje',
        surveys_view: 'Rezultate Sondaje', 
        surveys_edit_single: 'Sondaj',
        surveys_view_single: 'Sondaj',
        notifications: 'Notificări',
        notifications_single: 'Notificare',

    }

 } 

 export default ro;