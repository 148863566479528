<template>
    <div class="page list">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-md-12">
                    <h3 class="mt-5 mb-4 font-weight-bold">
                        <span v-if="!$route.query.type">{{ $t('modules.' + module.name) }}</span> <span v-if="$route.query.type">{{$t('modules.' + $route.query.type)}}</span> <i v-if="!payload" class="pull-right fas fa-spinner fa-spin"></i><br><br>
                    </h3>
                </div>
            </div>
            <!-- Items -->
            <div class="row">
                <div class="col-md-12" v-if="payload">
                    <div class="items">
                        <div v-if="payload.data.length === 0">
                            {{ $t('empty') }}
                        </div>
                        <router-link class="item customBorder shadow" v-for="item in payload.data" v-bind:key="item.id" :to="module.resource + '/' + item.id">
                            <div v-for="field in module.fields.index" class="td">
                                <img v-if="item.images && item.images.length > 0 && field.label == 'labels.title'" :src="'https://sm.weareomni.eu/' + item.images[0].original" alt="" class="img-thumbnail table-image">
                                <img v-if="item.image && field.label == 'labels.name' " :src="'https://sm.weareomni.eu/' + item.image.original" alt="" class="img-thumbnail table-image">
                                <div class="rest">
                                    <label>{{ $t(field.label) }}<span v-if="field.label"></span> </label>
                                    <div class="value">{{ field.field(item) }}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- Pagination -->
                <div class="col-md-12" v-if="payload">
                    <br>
                    <pagination :limit="1" :show-disabled="true" align="right" :data="payload" @pagination-change-page="getPage">
                        <span slot="prev-nav">
                            <i class="fas fa-chevron-left"></i>
                        </span>
                        <span slot="next-nav">
                            <i class="fas fa-chevron-right"></i>
                        </span>
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.pull-right {
    float: right;
}

.customBorder {
    height: 102px;
    margin-bottom: 15px;
    border-radius: 6px;


}

.table-image {
    display: inline-block;
    margin-top: -15px;
    margin-right: 20px;
}

.rest {
    display: inline-block;
}

.items {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
}



.item {
    width: 100%;
    display: table-row;
}

.td {
    vertical-align: top;
    display: inline-block;
    margin-right: 50px;
    display: table-cell;
    padding: 26px 40px;

    max-width: 400px;
}

.td:nth-child(2) {
    float: right;
    text-align: right;
}



.field {}

label {
    font-weight: 400;
    display: block;
    font-size: 14px;
    margin-bottom: 0px;
}

.value {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.table-td-image {
    position: relative;
    top: -10px;
}

.item {
    color: #000;
}

.item:hover {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    border-radius: 6px;
}

.customBorder:hover {
    background-color: #004680;
    border-radius: 6px;
}
</style>
<script>
import Pagination from 'laravel-vue-pagination';
export default {
    props: ['module'],
    components: {
        'pagination': Pagination,
    },
    data() {
        return {
            payload: null,
            type: null,
            sort_by: 'created_at',
            sort_direction: 'DESC',
        }
    },
    mounted() {

        // Grab URL query
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }

        // Grab module settings
        if (this.module.sort_by) {
            this.sort_by = this.module.sort_by;
        }
        if (this.module.sort_direction) {
            this.sort_direction = this.module.sort_direction;
        }


        this.getPage(this.page);

    },
    watch: {
        '$route.query.type': function(newType, oldType) {
            this.type = newType;
            this.getPage();
        }
    },
    methods: {

        getPage(page = 1) {

            this.$http.post(
                    this.module.resource + '/search', 
                    {
                        page: page,
                        type: this.type,
                        sort_by: this.sort_by,
                        sort_direction: this.sort_direction,
                    }, 
                    {
                        headers: {
                            'Locale': this.$i18n.locale
                    }
                })
                .then(res => {

                    this.payload = res.data;

                    // Set URL query
                    let query = {
                        page: page,
                        type: this.type,
                    }
                    this.$router.push({ path: '/', query: query });
                    this.$router.push({ path: this.module.resource, query: query });

                })
                .catch(err => {

                });
        }
    }
}
</script>