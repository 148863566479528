<template>
	<div class="index">
		<router-view ref="view" :module="module"></router-view>
	</div>
</template>
<script>
export default { 
	data() { 
		return {
			module: {
                
				name: 'managers', 
	            resource: 'managers', 

	            sort_by: 'order',
	            sort_direction: 'ASC',

	            fields: {
	            	index: [

	            		{
                            field: item => item.first_name + ' ' + item.last_name,
                            fieldname: 'name',
                            label: 'labels.name',
                            filter: 'text',
                        },
                        {
                            field: item => item.function,
                            fieldname: 'function',
                            label: 'labels.function',
                            filter: 'text',
                        },
	            	],

	            	view: [
	            		
	            	]
	            }
            }
		}
	}
}
</script>