<template>
<div class="container">
    <div class="languages">
        <label style="display: inline-block;">Selectati Limba:</label>&nbsp;
        <img src="/images/ro.jpg" alt="Romana" @click="setLang('ro')">&nbsp;
        <img src="/images/hu.jpg" alt="Magyar" @click="setLang('hu')">&nbsp;
        <img src="/images/en.jpg" alt="English" @click="setLang('en')"> &nbsp;
        <img src="/images/de.jpg" alt="Deutsch" @click="setLang('de')">&nbsp;
    </div>
</div>
</template> 
<script>
export default {
    data() {
        return {
            lang: 'ro', // fallback
        }
    },
    mounted() { 
        /*let lang = localStorage.getItem('lang');
        if(lang){
            this.setLang(lang)
        }*/
    },
    methods: {
        setLang(lang) {      
            this.$i18n.locale = lang; 
            localStorage.setItem('lang', lang);
            this.lang = lang;

            let route = this.$router.currentRoute;
            console.log(route);
            
            this.$router.push('/');
            this.$nextTick(() => {
                this.$router.push({ path: route.path, query: route.query, params: route.params });    
            });
            

            //this.$router.push(this.$router.currentRoute);

        },
        changed: function() {
            this.setLang(this.lang);

        }
    }
}
</script>