 const en = {
    reply_email: 'Email', 
    reply_phone: 'Phone Call', 
    1: 'Yes',
    0: 'No',
    done: 'Resolved',
    unresolved: 'Unresolved',
    annuled: 'Annuled',
 	backend: 'Back End',
 	view: 'View', 
 	edit: 'Edit',
    add: 'Add',
    no_role: 'No Role',
 	interactions: {
 		confirmation_required: 'Confirmation Required',
 		confirmation_delete: 'Are you sure you want to remove the following item ?',
 	}, 
 	buttons: {
 		add: 'ADD',
 		edit: 'EDIT', 
 		delete: 'DELETE', 
 		save: 'SAVE', 
 		cancel: 'CANCEL',
        accept: 'ACCEPT',
        revoke: 'REVOKE',
        resolve: 'RESOLVE',
        send: 'SEND',
 	},
 	labels: {
        cnp: 'C.N.P.',
        birthday: 'Birthday', 
        profession: 'Profession',
        workplace: 'Workplace',
        direction: 'Direction',
        in: 'In',
        out: 'Out',
        search: 'Search',
        structure: 'Structure', 
        members: 'Members',
        no_parent: 'No Parent',
        parent: 'Parent',
        status_modified: 'Status Modified',
        posts: 'Posts',
        redirected: 'Redirected', 
        all: 'All',
        send_to_institution: 'Forward to institution', 
        posts: 'Posts',
        problem_types: 'Problem Categories',
        featured: 'Featured',
        id: 'ID',
        city: 'City',
 		details: 'Details', 
 		title: 'Title',
 		type: 'Type',
        text: 'Text',
 		created_at: 'Created At',
 		filesize: 'File Size',
 		description: 'Description',
        uploads: 'Uploads',
        showing_results: 'Showing',
        of: 'of',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        reply_method: 'Reply Method',
        status: 'Status',
        rating: 'Rating',
        ratings_number: 'People who rated',
        ratings_average: 'Average rating',
        more_info: 'More Info',
        images: 'Images',
        location: 'Location',
        category: 'Category',
        link: 'Link',
        start_date: 'Start Date',
        end_date: 'End Date',
        organizer: 'Organizer',
        no_results: 'No results found',
        profile_image: 'Image',
        party_name: 'Political Party',
        role: 'Role',
        predicted_outcomes: 'Predicted Outcomes',
        investment_costs: 'Investment Costs',
        objective: 'Objectives',
        studies: 'Studies',
        message:'Mesaj',
        appointed_date: 'Appointed Date',
        function: 'Function',
        partner: 'Partner',
 	},
 	toasts: { 
        success: 'Succes',
 		delete_success: 'Item deleted',
 		create_success: 'Item created',
 		update_success: 'Item updated', 
 		request_error: 'There was an error processing the request',
 		validation_error: 'Please fill out the marked fields',
 	},
    auth: {
     	email_label: 'Email address',
     	password_label: 'Password',
        connect: 'CONNECT',
        logout: 'LOG OUT',
    },
    sections: { 
        contacts: 'Contacts',
        admin: 'Admin',
        interact: 'Interact',
        news: 'News / Events',
        turism: 'Tourism',
        transport: 'Transport and Locations',
        general_points: 'General Interest Points', 
        what_we_do: 'C.J. Ilfov / What we do',
        about_us: 'City Hall',
    },
    modules: {
        people: 'People', 
        people_single: 'Person', 
        companies: 'Companies',
        companies_single: 'Company',
        organizations_single: 'Organization',
        organizations: 'Organizations',
        import: 'Import Interest Points', 
        institutions: 'Institutions',
        institutions_single: 'Institution',
        accounts: 'Active Users',
        accounts_single: 'User',
        account_requests: 'New User Requests',
        account_requests_single: 'New User Request',
    	documents: 'Required Documents',
    	documents_single: 'Required Document',
        information_requests: 'Information Requests',  
        information_requests_single: 'Information Request',  
        feedback: 'Feedback',
        feedback_single: 'Feedback',
        proposals: 'Initiatives / Proposals',
        proposals_single: 'Proposal',
        problems: 'Reported Problems',
        problems_single: 'Problem',
        news: 'News',
        news_single: 'News', 
        events: 'Events',
        events_single: 'Events',
        interest_points: 'Points of Interest',
        interest_points_single: 'Point of Interest',
        museums: 'Museums',
        monuments: 'Monuments',
        parks: 'Parks', 
        agrement: 'Junktions',
        monastery: 'Churches / Monasteries',
        hotels: 'Hotels',
        hospitals: 'Hospitals',
        commerce: 'Commercial Centers',
        autoinfo: 'Auto Info',
        police: 'Police Stations',
        cityhalls: 'City Halls',
        schools: 'Schools',
        sport: 'Sport Centers',
        roads: 'Roads',
        roads_single: 'Road',
        sewers: 'Water Sewers',
        health: 'Health Network',
        waste: 'Waste',
        public_services: 'Public Services',
        councillors: 'Councillors',
        councillors_single: 'Councillor',
        services: 'Services',
        projects: 'Strategic Projects', 
        admins: 'Admins',
        admins_single: 'Admin',
        managers_single: 'Co-worker',
        abouts: 'About Us',
        managers: 'Managers',
        activities: 'Activities',
        partnerships: 'Partnerships',
        projects_single: 'Strategic Project',
        activities_single: 'Activity',
        partnerships_single: 'Partnerships',

        
    }
 } 

 export default en;