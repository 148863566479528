<template>
	<div class="index">
		<router-view ref="view" :module="module"></router-view>
	</div>
</template>
<script>
export default { 
	data() { 
		return {
			module: {
				name: 'required_documents', 
	            resource: 'required-documents', 

	            fields: {
	            	index: [

	            		 {
                            field: item => item.title,
                            fieldname: 'title',
                            label: 'labels.title',
                            filter: 'text',
                        },
                        {
                            field: item => (item.type) ? ((item.type.section) ? item.type.section.name  : '') : '',
                            fieldname: 'type.document_section_id', 
                            label: 'labels.section',
                            filter: 'dbselect',
                            endpoint: 'required-document-sections',
                            width: '200px'
                        }, 
	            	],

	            	view: [
	            		
	            	]
	            }
            }
		}
	}
}
</script>