 <template>
	<div class="schedule">
		<table class="table view-table borderless table-sm">

            <tr class="field" v-for="i in 7" :key="i"> 
                <td class="label">{{ $t('days.' + i) }}: </td>
                <td class="value"> 
                	<div class="time" v-for="interval in days[i]">{{ $moment(interval.start_time, 'HH:mm:ss').format('HH:mm') }} - {{ $moment(interval.end_time, 'HH:mm:ss').format('HH:mm') }}</div>
                	<div class="time" v-if="days[i].length === 0">{{ $t('labels.closed') }}</div>
                </td>
            </tr>    
            
        </table>
	</div>
 </template>
 <style scoped>
    .value {
        padding-top: 10px;
        text-align: right;
    }
 </style>
 <script>
 	export default {

 		props: ['payload'], 
 		data() {

 			return {

 				days: [
                    null, 
 					[],
 					[],
 					[],
 					[],
 					[],
 					[],
 					[],
 				],

 			}

 		},
 		mounted() {

 			for (var i = 0; i < this.payload.schedule.length; i++) {
 				var time = this.payload.schedule[i];
 				this.days[time.weekday].push(time); 				
 			}

 		}
 	}
 </script>
